import React from 'react';

const CookiesPolicyComponent = () => {
  return (
    <div className="mt-5 mb-5">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Cookies and Tracking Policy
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              At Ummah Relief International, we value your privacy and strive to
              provide you with a transparent understanding of how we utilize
              cookies and tracking technologies on our website. This policy
              outlines the types of cookies we use, why we use them, and how you
              can manage your preferences.
            </p>
          </div>
          {/* 2 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              What are Cookies?
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              Cookies are small text files that are stored on your device
              (computer, smartphone, or tablet) when you visit a website. They
              contain information about your browsing activity on that site and
              are commonly used to enhance user experience and provide
              analytical insights.
            </p>
          </div>
          {/* 3 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Types of Cookies We Use
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              <ol className="list-group list-group-numbered">
                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  style={{ border: 'none' }}
                >
                  <div class="ms-2 me-auto">
                    <span className="fw-bold">Essential Cookies: </span>

                    <span>
                      These cookies are necessary for the functioning of our
                      website. They enable basic features such as page
                      navigation and access to secure areas of the site. Without
                      these cookies, certain parts of our website may not
                      function properly.
                    </span>
                  </div>
                </li>

                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  style={{ border: 'none' }}
                >
                  <div class="ms-2 me-auto">
                    <span className="fw-bold">Performance Cookies: </span>

                    <span>
                      These cookies collect anonymous information about how
                      visitors use our website, such as which pages are visited
                      most frequently and if any error messages are encountered.
                      This information helps us analyze and improve the
                      performance of our website.
                    </span>
                  </div>
                </li>

                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  style={{ border: 'none' }}
                >
                  <div class="ms-2 me-auto">
                    <span className="fw-bold">Functionality Cookies: </span>

                    <span>
                      These cookies enable our website to remember choices you
                      make (such as language preferences or region) and provide
                      enhanced features. They may also be used to personalize
                      your experience by remembering your preferences for future
                      visits.
                    </span>
                  </div>
                </li>

                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  style={{ border: 'none' }}
                >
                  <div class="ms-2 me-auto">
                    <span className="fw-bold">
                      Advertising/Targeting Cookies:{' '}
                    </span>

                    <span>
                      These cookies are used to deliver advertisements that are
                      relevant to your interests based on your browsing history
                      and behavior on our website and other sites. They may also
                      be used to limit the number of times you see an
                      advertisement and measure the effectiveness of advertising
                      campaigns.
                    </span>
                  </div>
                </li>
              </ol>
            </p>
          </div>
          {/* 4 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Why We Use Cookies
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              <p className="tri-card-p" style={{ textAlign: 'justify' }}>
                <ul className="list-group list-group-dotted">
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    style={{ border: 'none' }}
                  >
                    <div class="ms-2 me-auto">
                      <span className="fw-bold">Enhance User Experience: </span>

                      <span>
                        Cookies allow us to customize and personalize your
                        experience on our website, making it more user-friendly
                        and efficient.
                      </span>
                    </div>
                  </li>

                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    style={{ border: 'none' }}
                  >
                    <div class="ms-2 me-auto">
                      <span className="fw-bold">Analytics: </span>

                      <span>
                        Cookies help us gather valuable insights into how our
                        website is used, allowing us to identify areas for
                        improvement and optimize our content and services.
                      </span>
                    </div>
                  </li>

                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    style={{ border: 'none' }}
                  >
                    <div class="ms-2 me-auto">
                      <span className="fw-bold">Advertising: </span>

                      <span>
                        Cookies enable us to deliver targeted advertisements
                        that are tailored to your interests, improving the
                        relevance and effectiveness of our marketing efforts.
                      </span>
                    </div>
                  </li>

                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    style={{ border: 'none' }}
                  >
                    <div class="ms-2 me-auto">
                      <span className="fw-bold">Functionality: </span>

                      <span>
                        Cookies support various website functionalities, such as
                        remembering your preferences and settings, providing a
                        seamless and personalized browsing experience.
                      </span>
                    </div>
                  </li>
                </ul>
              </p>
            </p>
          </div>
          {/* 5 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Managing Your Cookie Preferences
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              You have the option to manage your cookie preferences through your
              browser settings. Most web browsers allow you to control cookies
              through settings or preferences menus, where you can choose to
              accept, reject, or delete cookies. However, please note that
              disabling certain cookies may impact the functionality and
              performance of our website.
            </p>
          </div>
          {/* 6 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Your Consent
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              By continuing to use our website, you consent to the use of
              cookies and tracking technologies as described in this policy. If
              you do not agree with the use of cookies, you may choose to
              disable them through your browser settings, although this may
              affect your browsing experience on our website.
            </p>
          </div>
          {/* 7 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Updates to This Policy
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              We may update this Cookies and Tracking Policy from time to time
              to reflect changes in our practices or legal requirements. Any
              updates will be posted on this page, and we encourage you to
              review this policy periodically for any changes.
            </p>
          </div>
          {/* 8 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Contact Us
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              If you have any questions or concerns about this Cookies and
              Tracking Policy, please contact us at:
              <br />
              <div className="d-flex flex-column gap-2 mt-2">
                <div>
                  <span className="fw-bold">Email: </span>
                  <a href="mailto:ummah@ummahrelief.org">
                    ummah@ummahrelief.org
                  </a>
                </div>
                <div>
                  <span className="fw-bold">Address: </span>
                  <span>339 W River Rd, Elgin IL 60123</span>
                </div>
                <div>
                  <span className="fw-bold">Mailing Address: </span>
                  <span>P.O. Box 1426, Elgin, IL 60121</span>
                </div>
                <div>
                  <span className="fw-bold">Phone: </span>
                  <span>1-800-713-4482</span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicyComponent;
