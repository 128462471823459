import React from 'react';

const TermOfUseComponent = () => {
  return (
    <div className="mt-5 mb-5">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Terms of Use
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              Welcome to Ummah Relief International! By accessing and using our
              website, you agree to comply with and be bound by the following
              terms and conditions of use. Please read these terms carefully
              before using our website.
            </p>
          </div>
          {/* 2 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Acceptance of Terms
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              By accessing or using our website, you acknowledge that you have
              read, understood, and agree to be bound by these Terms of Use. If
              you do not agree with any part of these terms, you may not access
              or use our website.
            </p>
          </div>
          {/* 3 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Use of Website
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              You agree to use our website for lawful purposes only and in a
              manner that does not infringe upon the rights of others or
              restrict or inhibit their use and enjoyment of the site. You
              further agree not to engage in any conduct that may damage,
              disable, or impair the functionality of our website or interfere
              with any other party’s use of the site.
            </p>
          </div>
          {/* 4 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Privacy Policy
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              Your use of our website is also subject to our Privacy Policy,
              which outlines how we collect, use, and protect your personal
              information. By using our website, you consent to the collection
              and use of your information as described in our Privacy Policy.
            </p>
          </div>
          {/* 5 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Cookies and Tracking
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              Our website uses cookies and similar tracking technologies to
              enhance user experience and analyze website traffic. By continuing
              to use our website, you consent to the use of cookies as described
              in our Cookies and Tracking Policy. You may manage your cookie
              preferences through your browser settings.
            </p>
          </div>
          {/* 6 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Intellectual Property
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              All content on our website, including text, graphics, logos,
              images, and software, is the property of Ummah Relief
              International or its licensors and is protected by copyright and
              other intellectual property laws. You may not reproduce,
              distribute, or modify any content from our website without prior
              written consent.
            </p>
          </div>
          {/* 7 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Links to Third-Party Websites
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              Our website may contain links to third-party websites for your
              convenience. We do not endorse or control these websites and are
              not responsible for their content, privacy policies, or practices.
              Accessing third-party websites through links on our site is done
              at your own risk.
            </p>
          </div>
          {/* 8 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Limitation of Liability
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              In no event shall Ummah Relief International be liable for any
              direct, indirect, incidental, special, or consequential damages
              arising out of or in any way connected with your use of our
              website or the content contained therein.
            </p>
          </div>
          {/* 9 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Changes to Terms
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              We reserve the right to modify or update these Terms of Use at any
              time without prior notice. Any changes will be effective
              immediately upon posting on this page. Your continued use of our
              website following the posting of changes constitutes your
              acceptance of such changes.
            </p>
          </div>
          {/* 10 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Contact Us
            </div>

            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              If you have any questions or concerns about these Terms of Use,
              please contact us at:
              <br />
              <div className="d-flex flex-column gap-2 mt-2">
                <div>
                  <span className="fw-bold">Email: </span>
                  <a href="mailto:ummah@ummahrelief.org">
                    ummah@ummahrelief.org
                  </a>
                </div>
                <div>
                  <span className="fw-bold">Address: </span>
                  <span>339 W River Rd, Elgin IL 60123</span>
                </div>
                <div>
                  <span className="fw-bold">Mailing Address: </span>
                  <span>P.O. Box 1426, Elgin, IL 60121</span>
                </div>
                <div>
                  <span className="fw-bold">Phone: </span>
                  <span>1-800-713-4482</span>
                </div>
              </div>
            </p>
          </div>
          {/* 11 */}
          <div className="col-12 my-0 my-lg-2">
            <p className="tri-card-p" style={{ textAlign: 'justify' }}>
              This Terms of Use page outlines the terms and conditions governing
              your use of our website, including acceptance of terms, privacy
              policy, cookies and tracking, intellectual property, limitations
              of liability, and contact information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermOfUseComponent;
